<template>
  <div>
    <v-card>
      <v-toolbar dark color="dark">
        <v-toolbar-title>{{ $t("leadProcessingStatus") }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <Doughnut
          :chartData="processedData"
          :chartLabels="chartLabels"
          :chartColors="chartColors"
          v-if="dataProcessed"
        /><!-- wenn daten für chart aufbereitet wurden -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import store from "@/store";
import Doughnut from "@/components/charts/Doughnut";
export default {
  name: "ProcessingStatusCard",

  components: {
    Doughnut,
  },

  data() {
    return {
      dataProcessed: false,
      chartLabels: [
        this.$t("Unedited"),
        this.$t("Contacted"),
        this.$t("Scheduled"),
        this.regularOrPayCampaign(),
        this.$t("client"),
        this.$t("Lost"),
      ],
      chartColors: [
        "#d7474a",
        "#FFC107",
        "#CDDC39",
        "#abc24d",
        "#79BF09",
        "black",
      ],
      companyID: store.getters["auth/current_sid"],
    };
  },

  props: ["data", "hasPay"],

  created() {
    this.regularOrPayCampaign();
  },

  mounted() {
    /* @ mounted Hook, the computed properties are done with calculating.
    Problem was, that the chart was starting before the computed prop was ready */
    this.dataProcessed = true;
  },

  computed: {
    processedData() {
      /* Aufbereiten der Daten für die Verwendung im Chart */

      let contactedCount = 0;
      let sheduledCount = 0;
      let clientCount = 0;
      let begunCount = 0;
      let lostCount = 0;
      let uneditedCount = 0;

      this.data.forEach((item) => {
        if (item.identityIsClient === "1") {
          ++clientCount;
        } else if (item.isLost === "1") {
          ++lostCount;
        } else if (item.hasRedeemed === "1") {
          ++begunCount;
        } else if (item.isScheduled === "1") {
          ++sheduledCount;
        } else if (item.isContacted === "1") {
          ++contactedCount;
        } else {
          ++uneditedCount;
        }
      });

      return [
        uneditedCount,
        contactedCount,
        sheduledCount,
        begunCount,
        clientCount,
        lostCount,
      ];
    },
  },

  methods: {
    regularOrPayCampaign() {
      // decide what word is used for "redeemed". "Redeemed" or "Bought"
      // This based on campaign type
      if (this.hasPay === "1") {
        return this.$t("Bought");
      } else {
        return this.$t("begun");
      }
    },
  },
};
</script>
