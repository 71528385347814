<template>
  <v-container>
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="$t('pageDescriptions.campaignsStatistik')"
    />

    <template v-if="!isError">
      <v-row align="stretch" v-if="!fetchinData">
        <v-col class="col-12 col-md-6 col-lg-6">
          <CampaignInfoCard
            v-if="!fetchinData"
            :data="data.campaign"
            :numLeads="data.campaign_members.length"
          />
        </v-col>

        <v-col class="col-12 col-md-6 col-lg-6">
          <ProcessingStatusCard
            v-if="!fetchinData"
            :data="data.campaign_members"
            :hasPay="data.campaign.hasMitfitPay"
          />
        </v-col>

        <v-col class="col-12 col-md-6 col-lg-6">
          <LeadDevelopmentCard
            v-if="!fetchinData"
            :data="data.campaign_members"
          />
        </v-col>

        <v-col class="col-12 col-md-6 col-lg-6">
          <GenderDistributionCard
            v-if="!fetchinData"
            :data="data.campaign_members"
          />
        </v-col>

        <v-col class="col-12" v-if="!fetchinData">
          <CampaignLeadsList
            :data="data.campaign_members"
            :hasPay="data.campaign.hasMitfitPay"
            :campaign="data.campaign"
          />
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <h1>Es ist ein Fehler aufgetreten</h1>
      <p>{{ errorDetail }}</p>
    </template>
  </v-container>
</template>

<script>
import store from "@/store";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import PageHeader from "@/components/ui/PageHeader";
import CampaignInfoCard from "@/components/campaigns/manage/CampaignInfoCard";
import ProcessingStatusCard from "@/components/campaigns/manage/ProcessingStatusCard";
import LeadDevelopmentCard from "@/components/campaigns/manage/LeadDevelopmentCard";
import GenderDistributionCard from "@/components/campaigns/manage/GenderDistributionCard";
import CampaignLeadsList from "@/components/campaigns/manage/CampaignLeadsList";

export default {
  name: "Campaign",

  components: {
    PageHeader,
    CampaignInfoCard,
    ProcessingStatusCard,
    LeadDevelopmentCard,
    GenderDistributionCard,
    CampaignLeadsList,
  },

  data() {
    return {
      pageTitle: null,
      pageDescription:
        "Pellentesque in ipsum id orci porta dapibus. Cras ultricies ligula sed magna dictum porta.",
      fetchinData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      data: [], // wrapper for fetched data
      currentTimestamp: Math.floor(Date.now() / 1000),
      isError: false,
      errorDetail: null,
    };
  },

  props: [],

  created: function() {
    // is a company changer allowed?
    this.$store.commit("companyChangeOption/SET_ALLOWED", false);

    this.getData();
  },

  methods: {
    async getData() {
      this.fetchinData = true;
      let response = await this.getRequest(
        "companies/" + this.companyID + "/campaigns/" + this.$route.params.id
      );
      this.fetchinData = false;

      if (response.status === 200) {
        this.data = response.data.data;

        if (this.data.campaign.errors) {
          this.isError = true;
          this.errorDetail = this.data.campaign.errors.detail;
        } else {
          this.isError = false;
          this.pageTitle = this.data.campaign.title;
        }
      }
    },
  },

  computed: {
    //
  },

  /* all requests by mixin */
  mixins: [manageApi],
};
</script>
