<template>
  <v-form ref="form" v-model="isFormValid" v-on:submit.prevent="exportData()">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-select
            v-model="what"
            :rules="[rules.required]"
            :items="dataOptions"
            item-text="name"
            item-value="value"
            :label="$t('exportLeadsWhichData')"
          ></v-select>
        </v-col>

        <v-col cols="12">
          <v-checkbox
            v-model="optin"
            :rules="[rules.required]"
            :label="$t('exportItemConfirmationText')"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <div class="d-flex align-end flex-column">
            <v-row>
              <v-btn color="grey" text @click="$emit('closeDialog')">{{
                $t("cancel")
              }}</v-btn>
              <v-btn
                v-if="isFormValid"
                color="green"
                dark
                @click="exportData"
                :loading="fetchinData"
              >
                {{ $t("export") }}
              </v-btn>
              <v-btn v-else disabled>{{ $t("export") }}</v-btn>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import store from "@/store";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import Papa from "papaparse";

export default {
  name: "ExportLeads",
  data() {
    return {
      fetchinData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      what: null,
      isFormValid: false,
      optin: false,
      dataOptions: [
        {
          name: this.$t("All"),
          value: "all",
        },
        {
          name: this.$t("notContacted"),
          value: "notcontacted",
        },
        {
          name: this.$t("Scheduled"),
          value: "scheduled",
        },
        {
          name: this.$t("alreadyClient"),
          value: "client",
        },
        {
          name: this.$t("Lost"),
          value: "lost",
        },
      ],
      rules: {
        required: (value) => !!value || this.$t("required"),
      },
    };
  },

  props: ["campaignId"],

  created: function () {
    this.$store.watch(
      () => store.getters["auth/current_sid"],
      (value) => {
        this.companyID = value;
        this.getData();
      }
    );
  },

  methods: {
    async exportData() {
      /* 
      GET campaigns/{campaignID}/leads
      GET campaigns/{campaignID}/leads?filter[isContacted]=0
      GET campaigns/{campaignID}/leads?filter[isScheduled]=1
      GET campaigns/{campaignID}/leads?filter[isClient]=1
      GET campaigns/{campaignID}/leads?filter[isLost]=1
      */

      let filter = "";

      if (this.what === "contacted") {
        filter = "?filter[isContacted]=1";
      } else if (this.what === "notcontacted") {
        filter = "?filter[isContacted]=0";
      } else if (this.what === "scheduled") {
        filter = "?filter[isScheduled]=1";
      } else if (this.what === "alreadyClient") {
        filter = "?filter[isClient]=1";
      } else if (this.what === "lost") {
        filter = "?filter[isLost]=1";
      }

      const url = `${this.$manageApiUrl}campaigns/${this.campaignId}/leads${filter}`;
      const response = await fetch(url, {
        headers: {
          Token: this.$store.getters["auth/manageToken"],
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      // export as CSV

      const csv = Papa.unparse(data, {
        delimiter: ";",
      });
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${this.campaignId}_${this.what}_export.csv`;
      link.click();
      link.remove();
      this.$emit("closeDialog");
    },
  },
  mixins: [manageApi],
};
</script>
