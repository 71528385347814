<template>
  <v-card height="100%">
    <v-toolbar dark color="dark">
      <v-toolbar-title>{{ $t("info") }}</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <v-row>
        <v-col class="col-12">
          <table class="default-table" v-if="data.id" style="width:100%">
            <tbody>
              <tr>
                <td class="font-weight-bold">{{ $t("campaign") }}</td>
                <td>
                  {{ data.title }}
                  <v-btn
                    v-if="data.isPageless !== '1'"
                    :href="`${this.$landingpageUrl}?uuid=${data.uuid}`"
                    target="_blank"
                    class="mx-2"
                    fab
                    dark
                    x-small
                    elevation="0"
                    color="blue"
                  >
                    <v-icon small dark>mdi-eye</v-icon>
                  </v-btn>
                </td>
              </tr>

              <!-- <tr>
                <td class="font-weight-bold">{{ $t("tool") }}</td>
                <td>
                  {{ data.page_game_type }}
                  <v-chip
                    v-if="
                      data.page_has_mitfitpay &&
                        data.page_has_mitfitpay !== '0' &&
                        data.page_has_mitfitpay != null &&
                        data.page_has_mitfitpay !== 0
                    "
                    small
                    class="ma-2"
                    color="green"
                    text-color="white"
                  >
                    <v-icon x-small left>mdi-credit-card</v-icon>
                    PAY
                  </v-chip>
                </td>
              </tr> -->

              <tr>
                <td class="font-weight-bold">{{ $t("runtime") }}</td>
                <td>
                  <span v-if="data.dateStart">{{
                    this.formatDateNoTime(data.dateStart)
                  }}</span>
                  <span v-if="data.dateEnd">
                    - {{ this.formatDateNoTime(data.dateEnd) }}</span
                  >
                  <span v-else>
                    <span v-if="data.dateStart"> - offen</span></span
                  >
                </td>
              </tr>

              <tr v-if="this.data.hasMitfitPay !== '1'">
                <td class="font-weight-bold">{{ $t("newLeads") }}</td>
                <td>{{ numLeads }} {{ $t("contacts") }}</td>
              </tr>

              <tr v-else-if="this.data.hasMitfitPay === '1'">
                <td class="font-weight-bold">{{ $t("sales") }}</td>
                <td>{{ numLeads }} {{ $t("sales") }}</td>
              </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import store from "@/store";
import helpers from "@/mixins/helpers"; // import mixin
export default {
  name: "CampaignInfoCard",

  props: ["data", "numLeads"],

  data() {
    return {
      page_has_mitfitpay: null,
      fetchinData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
    };
  },

  created: function() {
    // mitfitPAY

    if (this.data && this.data.hasMitfitPay) {
      this.page_has_mitfitpay = this.data.hasMitfitPay;
    }

    this.games_data_hasOrdered = this.data.hasOrdered;
    this.games_data_orderId = this.data.orderId;
  },

  mixins: [helpers],
};
</script>
